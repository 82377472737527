import { useState } from 'react';
import type { VFC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Login from '../../views/authentication/login/login';
import { useAuth } from '../../hooks/use-auth';

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: VFC<AuthGuardProps> = (props: AuthGuardProps) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  // TODO: check if user is authenticated,
  //  if false, return login screen.

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};