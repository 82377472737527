import React from 'react';
import Input from '@mui/joy/Input'

export const InputField = (props: any) => {
  const { InputProps, sx, inputProps, ...other } = props

  return (
    <Input
      color="neutral"
      inputprops={{
        ...inputProps,
        sx: {
          alignItems: 'center',
          display: 'flex',
          fontSize: 14,
          height: 'unset',
          lineHeight: 1.6,
          px: 1.5,
          py: 0.4,
          '&.MuiInputBase-inputAdornedStart': {
            pl: 0,
          },
        },
      }}
      inputlabelprops={{
        shrink: true,
        sx: {
          color: 'text.primary',
          fontSize: 14,
          fontWeight: 500,
          mb: 0.5,
          position: 'relative',
          transform: 'none',
          zIndex: 0,
        },
      }}
      sx={{
        ...sx,
      }}
      {...other}
      fullWidth
    />
  )
}