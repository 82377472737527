import React from 'react';
import { StyledEngineProvider } from '@mui/styled-engine';
import NavigationScroll from './layout/NavigationScroll';
import Routes from './routes';


function App() {
  return (
    <StyledEngineProvider injectFirst>
      <NavigationScroll>
        <Routes />
      </NavigationScroll>
    </StyledEngineProvider>
  );
};

export default App;
