import { lazy } from 'react';
import { AuthGuard } from '../components/auth/auth-guard';
import Loadable from '../ui-component/Loadable';
import DefaultLayout from '../layout'

const TransitDepartureOverview = Loadable(lazy(() => import('../views/transit-departure/overview')));
const TransitDepartureForm = Loadable(lazy(() => import('../views/transit-departure/form/transit-departure-form')));

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <DefaultLayout />
    </AuthGuard>
  ),
  children: [
    // {
    //   path: '*',
    //   element: <CustomsTransitOverview />,
    // },
    // {
    //   path: '/',
    //   element: <TransitDepartureOverview />,
    // },
    {
      path: '/transit/departures',
      element: <TransitDepartureOverview />,
    },
    {
      path: '/transit/departures/create',
      element: <TransitDepartureForm />,
    },
  ],
};

export default MainRoutes;