import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputField } from '../../../ui-component/input-field';
import { useAuth } from '../../../hooks/use-auth';
import { CssVarsProvider } from '@mui/joy/styles'
import GlobalStyles from '@mui/joy/GlobalStyles'
import CssBaseline from '@mui/joy/CssBaseline'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel'
import Typography from '@mui/joy/Typography'
import customTheme from '../../../theme'
import ColorSchemeToggle from '../../../layout/color-scheme-toggle'
import LogoSection from '../../../layout/MainLayout/LogoSection'

const Login = () => {
  const { login } = useAuth();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      // email: 'info@fidocs.tax',
      password: '',
      // password: '#FiDocsGekkie#',
    },
  });

  const onSubmit = async (data: any) => {
    await login(data.email, data.password);
  };


  return (
    <CssVarsProvider
      defaultMode="light"
      // disableTransitionOnChange
      theme={customTheme}
    >
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '30vw', // must be `vw` only
            '--Form-maxWidth': '700px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(4px)',
          backgroundColor: 'rgba(255 255 255 / 0.6)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div />
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: 10,
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <LogoSection width={400} />

            <Box sx={{ my: 8, mb: 0 }}>
              <Typography component="h2" fontSize="xl2" fontWeight="lg" sx={{ color: '#18736b', }}>
                YouDocs Transit Portal
              </Typography>
              <Typography level="body-md" sx={{ my: 1, mb: 3 }}>
                Please login to continue
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl required>
                <FormLabel>Username</FormLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => {
                    const { onChange, onBlur, value, name } = field
                    return (
                      <InputField
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        autoFocus
                        placeholder="Username"
                        autoComplete="off"
                        sx={{ borderColor: '#18736b' }}
                      />
                    )
                  }}
                />
              </FormControl>
              <FormControl required>
                <FormLabel>Password</FormLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => {
                    const { onChange, onBlur, value, name } = field
                    return (
                      <InputField
                        onChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        placeholder="*******"
                        type="password"
                        autoComplete="off"
                      />
                    )
                  }}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                sx={{
                  backgroundColor: '#18736b',
                  color: '#ece41d',
                  '&:hover': {
                    backgroundColor: '#ece41d',
                    color: '#18736b'
                  },
                }}>
                Log in
              </Button>
            </form>
          </Box>
          <Box component="footer" sx={{ py: 'auto' }}>
            <Typography level="body-md" textAlign="center">
              © FiDocs B.V. {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          // backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // backgroundColor: '#18736b',
          backgroundColor: '#fff',

          // backgroundImage:
          // 'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8)',
          // [theme.getColorSchemeSelector('dark')]: {
          // backgroundImage:
          // 'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831)',
          // },
        })}
      /> */}
    </CssVarsProvider>
  )

  // return (
  //   <Container sx={{ mt: 10 }}>
  //     <form onSubmit={handleSubmit(onSubmit)}>
  //       <Box
  //         sx={{
  //           alignItems: 'center',
  //           display: 'flex',
  //           mb: 3,
  //         }}
  //       >
  //         <Typography color="textPrimary" variant="h4">
  //           Login
  //         </Typography>
  //         <Box sx={{ flexGrow: 1 }} />
  //       </Box>
  //       <Grid container spacing={2}>
  //         <Grid item xs={12}>
  //           <Controller
  //             name="email"
  //             control={control}
  //             render={({ field }) => {
  //               const { onChange, onBlur, value, name } = field;
  //               return (
  //                 <InputField
  //                   onChange={onChange}
  //                   onBlur={onBlur}
  //                   name={name}
  //                   value={value}
  //                   autoFocus
  //                   label="Email address"
  //                   type="email"
  //                   autoComplete="off"
  //                 />
  //               );
  //             }}
  //           />
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Controller
  //             name="password"
  //             control={control}
  //             render={({ field }) => {
  //               const { onChange, onBlur, value, name } = field;
  //               return (
  //                 <InputField
  //                   onChange={onChange}
  //                   onBlur={onBlur}
  //                   name={name}
  //                   value={value}
  //                   label="Password"
  //                   type="password"
  //                   autoComplete="off"
  //                 />
  //               );
  //             }}
  //           />
  //         </Grid>
  //         <Grid item xs={12}>
  //           <Button color="info" fullWidth type="submit" variant="contained">
  //             Log In
  //           </Button>
  //         </Grid>
  //         <Grid item xs={12}>
  //           {/* <Button
  //           color="info"
  //           component={BrowserLink}
  //           to="/password-recovery"
  //           variant="text"
  //         >
  //           Forgot password
  //         </Button> */}
  //         </Grid>
  //       </Grid>
  //     </form>
  //   </Container>
  // );
}

export default Login;
