import { extendTheme } from '@mui/joy/styles';

export default extendTheme({
    fontFamily: {
        display: "'Inter', var(--joy-fontFamily-fallback)",
        body: "'Inter', var(--joy-fontFamily-fallback)",
    },
    components: {
        JoyInput: {
            styleOverrides: {
                root: {
                    borderColor: '#18736b',
                }
            },
        },
        JoyAutocomplete: {
            styleOverrides: {
                root: {
                    borderColor: '#18736b',
                }
            },
        },
        // JoyButton: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: '#18736b',
        //           color: '#ece41d',
        //           '&:hover': {
        //             backgroundColor: '#ece41d',
        //             color: '#18736b'
        //           },
        //         }
        //     }
        // }
    }
});
