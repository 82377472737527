import * as React from "react";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemButton from "@mui/joy/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { removeToken } from "../services/authentication-service";
import { useNavigate } from "react-router-dom";
import { closeSidebar } from "../utils/sidebar";
import LogoSection from "./MainLayout/LogoSection";
import { useDispatch, useSelector } from "react-redux";
import { MENU_OPEN } from "../store/actions";
import LocalShipping from "@mui/icons-material/LocalShipping";

export default function Sidebar() {
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();

    const itemHandler = (id, url) => {
        dispatch({ type: MENU_OPEN, id });
        navigate(url);
    };

    return (
        <React.Fragment>
            <Box
                className="SecondSidebar-overlay"
                sx={{
                    position: "fixed",
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    bgcolor: "background.body",
                    opacity: "calc(var(--SideNavigation-slideIn, 0) - 0.2)",
                    transition: "opacity 0.4s",
                    transform: {
                        xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
                        lg: "translateX(-100%)",
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Sheet
                className="SecondSidebar"
                sx={{
                    position: {
                        xs: "fixed",
                        lg: "sticky",
                    },
                    transform: {
                        xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
                        lg: "none",
                    },
                    borderRight: "1px solid",
                    borderColor: "divider",
                    transition: "transform 0.4s",
                    zIndex: 9999,
                    height: "100dvh",
                    width: "260px",
                    top: 0,
                    p: 2,
                    py: 3,
                    flexShrink: 0,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <LogoSection width={200} />
                <List
                    sx={{
                        "--ListItem-radius": "10px",
                        "--ListItem-minHeight": "32px",
                        "--List-gap": "5px",
                        mt: 3,
                    }}
                >
                    <ListSubheader
                        role="presentation"
                        sx={{ color: "#18736b" }}
                    >
                        Transit
                    </ListSubheader>
                    <ListItem>
                        <ListItemButton
                            id="transit-departures"
                            selected={
                                customization.isOpen.findIndex(
                                    (id) => "transit-departures" === id
                                ) > -1
                            }
                            sx={
                                customization.isOpen.findIndex(
                                    (id) => "transit-departures" === id
                                ) > -1
                                    ? {
                                          backgroundColor: "yellow",
                                          color: "#18736b",
                                      }
                                    : null
                            }
                            onClick={() =>
                                itemHandler(
                                    "transit-departures",
                                    "/transit/departures"
                                )
                            }
                        >
                            <ListItemDecorator>
                                <LocalShipping
                                    sx={{ fontSize: 24, color: "#18736b" }}
                                />
                            </ListItemDecorator>
                            <ListItemContent>
                                Transit Departures
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>
                {/* <MenuList /> */}
                <Box
                    sx={{
                        pl: 1,
                        mt: "auto",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <Typography fontWeight="lg" level="body2" />
                    </div>
                    <IconButton
                        variant="plain"
                        sx={{ ml: "auto" }}
                        onClick={() => {
                            removeToken();
                            navigate(0);
                        }}
                    >
                        <LogoutIcon sx={{ color: "#18736b" }} />
                    </IconButton>
                </Box>
            </Sheet>
        </React.Fragment>
    );
}
